import React from 'react'

export default function Footer() {
    return (
        <footer className="grid footer">
            <div className="content footer__box">
                <h3>Adamu J.</h3>
                <p> 2024 © Jeremiah Adamu. All Rights Reserved.</p>
          </div>
        </footer>
    )
}